.audii-form {
  .curernt-scan {
    font-size: 16px;

    .anticon {
      svg {
        fill: #69B480 !important;
      }
    }
  }
}
