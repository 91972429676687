@import "../assets/styles/variables";

body {
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

.login-page-right {
    background-color: $primaryColor;
    background-image: url('../assets/img/loginimg.svg');
    background-repeat:no-repeat;
    height: 100vh;
    background-size: cover;
    background-position: center;
}

@media (max-width: 640px) {
    .login-page-right {
        display: none;
    }
}
