.inputStyle {
    width: 35px !important;
    height: 35px !important;
    margin: 8px !important;
    font-size: 24px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
  .error {
    border: 1px solid red !important;
  }