@import "../../assets/styles/utilities";

.Suspicious {
    .suspicious-header {
        display: flex;
        justify-content: space-between;
        .suspicious-heading {
            font-size: 18px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.56;
            letter-spacing: normal;
            color: #3e3f42;
            width: 223px;
        }
        .search-bar {
            width: 220px
        }
        .table-row {
            height: 64px;
        }
    }
    .ant-table .ant-table-thead th div {
        font-size: 12px;
        color: #9ea0a5;
        text-transform: uppercase;
        min-width: 60px;
    }
    .grey-row {
        background-color: lightcyan
    }
}

.suspicious-action {
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.05), inset 0 2px 0 0 rgba(255, 255, 255, 0.05);
    background-image: linear-gradient(to top, #f6f7f9, #ffffff);
    @extend .primary-text;
}

.selected-waybill {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.57;
    @extend .primary-text;
}

.selected-waybill:hover {
    @extend .primary-text;
}