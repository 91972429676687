.create-trip-header {
    position: relative;
    &::after {
        content: '';
        display: block;
        width: 100%;
        position: absolute;
        top: 60px;
        border-top: 1px solid lightgrey;
        left: 0px;
    }
}

label.control-label[for="root_vendorId"] + .input-wrapper {
    max-width: 49%;
}