.manifest-closure-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .manifest-closure-card-style {
        padding: 0;
    }
    
    .manifest-closure-options {
        display: flex;
        // justify-content: space-evenly;
        // gap: 10%;

        .option-1{
            flex: 33%;
        }

        .option-2{
            flex: 67%;

            .ant-input-search-icon {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 600px)  {
    .manifest-closure-container.mweb-wrapper {
        .ant-form-item-label, .ant-form-item-control-wrapper, .ant-col-20 {
            display: block;
            width: 100%;
        }
    }
}