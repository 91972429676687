.risk-style {
    text-transform: uppercase;
    color: #000;
    padding: 6px 10px;
    border-radius: 6px;
    min-width: 78px;
    display: inline-block;
    text-align: center;
    font-size: 12px;
}

.HIGH-type {
    background-color: #e7492d;
    color: #fff;
}
  
.MEDIUM-type {
    background-color: #f6ab2f;
    color: #fff;
}
  
.NORMAL-type {
    background-color: #6758f3;
    color: #fff;
}
  
.LOW-type {
    background-color: #f2f2f5;
    color: #000;
}