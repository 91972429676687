@import "../../../assets/styles/buttons";
@import "./styles/additional";
@import "./styles/arrays";
@import "./styles/loading";

.form-group.field {
    gap: 10px;
    display: flex;
    min-height: 32px;

    &.field-error .error-detail {
        display: none;
    }

    &.field-boolean {
        align-items: center;
    }

    &.field-object {
        display: block;
        height: auto;
        position: relative;
        fieldset {
            &#root > .form-group.field {
                flex-basis: 100%;
            }
            &#root > .form-group.field.single-field-multi-row-form {
                flex-basis: 50% !important;
                & .ant-radio-group, .input-wrapper {
                    margin-left: -4%;
                }
            }
            legend {
                font-size: 16px;
                font-weight: 500;
            }
            legend#root__title { 
                margin-bottom: 15px;
            }
        }
        .column-splitter {
            height: 110%;
            position: absolute;
            top: -5px;
            width: 1px;
            background: linear-gradient(#f7f7f7, #a7a7a7, #f7f7f7);
            opacity: 0.6;
        }
        &.multi-row-field-objects {
            position: relative;
            margin: 5px 0px;
        }
    }

    &.hide-title {
        legend {
            display: none;
        }
        > .control-label {
            display: none;
        }
    }
    &.hide-label {
        legend {
            visibility: hidden;
        }
        > .control-label {
            visibility: hidden;
        }
    }

    &.form-bold-title > fieldset > legend {
        font-weight: bold;
    }

    .required {
        margin-left: 3px;
        color: red;
    }
    .control-label {
        flex: 1;
        box-sizing: border-box;
        max-height: 32px;
        padding: 5px 0px;
        font-weight: 500;
    }
    .input-wrapper, .ant-radio-group, .ant-select {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .input-wrapper.ant-select-wrapper .ant-select {
        height: 32px !important;
    }
    .file-input.input-wrapper {
        max-width: 50%;
    }
    .ant-radio-group {
        flex-direction: row;
        align-items: center;
    }
    fieldset.field.field-array {
        flex: 1;
        flex-direction: column;
        flex-wrap: nowrap;
    }
    .form-group.field.flex-2-input {
        & .input-wrapper, .ant-radio-group, .ant-select {
            flex: 2;
        }
        .file-input.input-wrapper {
            max-width: 67%;
        }
    } 
}

.lsn-form {
    display: flex;
    flex-direction: column;
    gap: 25px;
    .ant-input {
        color: #000;
    }
    &.column-fields fieldset {
        flex-direction: column;
    }
    &.row-fields {
        fieldset {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        .form-group.field .half-field {
            flex: 1;
        }
    }

    & .flex-column .form-group.field {
        flex-direction: column;
    }

    fieldset, .field-radio-group {
        display: flex;
        gap: 25px;
    }
    .checkbox label, .radio label span {
        display: flex;
        gap: 10px;
        align-items: center;
    }
    label.checkbox-inline span {
        padding: 0px 5px;
        display: flex;
        align-items: center;
    }
    div.checkboxes {
        display: flex;
        align-items: center;
        flex: 1 1;
        justify-content: space-between;
    }
}

.ant-calendar-range input.ant-calendar-input {
    pointer-events: none;
}

.error-text {
    color: #e9201c;
    flex: 1 1;
}
