.spinner-class {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iframe-container {
    height: 100%;
    margin-bottom: 2%;
}

.mt-20 {
    margin-top: 20px;
}