.label-sticker-multi-page-card{
    height: 2in;
    width: 3in;
    .ant-card-body{
        padding: 0px;
    }

    .label-sticker-multi-page-container{
        display: block;

        .multi-sticker-code-container {
            display: flex;
            flex-direction: column;
        
            .multi-sticker-barcode{
                display: flex;
                justify-content: center;
                width: 100%;
                height: 65px;
                & svg {
                    width: 100%;
                    height: 100%;
                }
            }
            hr {
                border:none;
                border-top:1px dashed black;
                color:#fff;
                background-color:#fff;
                height:1px;
                width:100%;
                margin: 3px 0;
              }
        }
        
    }
}