.drs {
  .pull-left {
    float: left;
  }

  .pull-right {
    float: right;
  }
}
.element-splitter {
  background-color: #eaeaea;
  border-radius: 50%;
  padding: 8px;
  font-size: 10px;
  display: inline-block;
  vertical-align: top;
}

.batching-field-exclude-pincode {
  flex-direction: column;
}