/* .elName {
    font-style: italic;
    font-weight: 600;
    
}

.elValue {
    margin-left: 5px;
    font-weight: bold;
    
} */
.els {

    display: grid;
	grid-template-columns: repeat(auto-fit, minmax(10ch, 1fr));	 
    grid-gap: 0;
    width: 100%;
    margin-top: 10px;
    padding: 3px 5px 3px 20px;

}

.els .elName {
    grid-column-start: 1;
    font-weight: 400;
    overflow-wrap: break-word;

}

.els .elValue {
    grid-column-start: span 1;
    font-weight: 600;
    text-align: justify;
    overflow-wrap: break-word;
}

.els .elRemove {
    grid-column-start: span 2;    
}