.login-card-container {
    min-width: 300px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;

    .login-card-card-container {
        margin-top: 20px;
        border: 1px solid #DFE3EE;
        border-radius: 5px;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.10%);
    }

    .ant-card-head-title {
        margin-top: 10px;
    }

    .login-card-title {
        font-size: 1.5rem;
        font-weight: 600;
        padding-top: 1.7rem;

    }

    .login-card-body {
        display: flex;
        flex-direction: column;

        .login-card-form {
            max-width: 450px;
            display: flex;
            flex-direction: column;
        }

        .login-card-button {
            width: 100%;
            padding: 10px 10px;
            border-radius: 2px;
            font-size: 1.1rem;
            font-weight: 400;
            height: 100%;
            margin-bottom: 0;
        }
    }
}