.custom-marker {
    background-color: #425994;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: bold;
  }
  
  .custom-div-icon {
    background: transparent;
  }

  .custom-dot {
    background-color: #425994;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    line-height: 20px;
    padding: 0;
  }

  .scroll-and-map{
        margin-top: 20px;
        display: flex;
        height: 365px;
        width: 100%;
  }
  .scroll{
        padding: 20px;
        border: 1px solid;
        border-radius: 10px 0px 0px 10px;
        border-color: #EEEEEE;
        width: 50%;
  }
  .map {
    width: 50%;
    height: 100%;
    .leaflet-container{
        height: 100%;
    }
    
}
.scroll-element{
    .ant-timeline-item-content {
        top:-9px
    }
    
}
.resolveOptions{
    height: 190px;
    border-style: solid;
    border-color: #EEEEEE;
    // text-align: center;
    padding: 20px;
}
.higlightedData{
    background-image: linear-gradient(180deg, rgba(255, 0, 0, 0), rgba(255, 0, 0, 0.28));
    border: 1px solid red;
    border-radius: 15px;
}
.timeLineMargin{
    margin: 20px; 
    margin-left: 6em; 
    margin-top: 40px; 
}
.timeLineTime{
    position: absolute; 
    left: -25%;
    top: 4px;
}
.timeLineELement{
    border-radius: 40px;
    background-color: #f1f4f3;
    padding: 5px; 
    left: -10px;
    padding-left: 10px; 
}
  
  