@import "../../../assets/styles/variables";

.sidebar {
    position: relative;
    box-shadow: 0 0 11px rgb(0 0 0 / 13%);
    .selected-indicator {
        position: absolute;
        right: 0px;
        width: 5px;
        height: 36px;
        background-color: $primaryColor;
        top: 8px;
        transition: all ease-in-out 0.25s;
        border-radius: 2px 0 0 2px;
    }
    .sidebar-item {
        height: 36px;
        .sidebar-label {
            font-size: 15px;
        }
        img {
            padding : 4px;
            width   : 36px;
            transition: all ease-in-out 0.25s;
        }
    }
    .selected-sidebar-item {
        font-weight: bold;
        img {
            background-color: $primaryColor;
            border-radius: 6px;
        }
        .sidebar-label {
            color: $primaryColor;
        }
    }
}