@import "../../../assets/styles/variables";

$elemRadius : 24px;
$elemBorder : 3px;
$lineHeight : 8px;

.timeline-line {
    height: $lineHeight;
    display: block;
    position: relative;
    width: 100%;
    border-radius: $elemRadius;
    .rider-element{
        position: absolute;
        top: -9px;
        height: $elemRadius;
        width: $elemRadius;
        border-radius: $elemRadius;
        border: $elemBorder solid $info-border;
        background-color: $info-text;
    }
    .timeline-elements-wrapper {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .timeline-element {
            height: 100%;
            height: $elemRadius;
            width: $elemRadius;
            border-radius: $elemRadius;
            border-width: $elemBorder;
            border-style: solid;
            background-color: #ebeff2;
            cursor: pointer;
            transition: all ease-in 0.2s;
            & .timeline-content {
                position: relative;
                top: -($elemRadius);
                left: -18px;
                width: max-content;
            }
            &.success-element {
                border-color: $success-border;
                &.selected-element {
                    background-color: $success-text;
                }
            }
            &.error-element {
                border-color: $error-border;
                &.selected-element {
                    background-color: $error-text;
                }
            }
            &.warning-element {
                border-color: $warning-border;
                &.selected-element {
                    background-color: $warning-text;
                }
            }
        }
    }
}