.print-label-scan-response{
    display: grid;
    grid-template-columns: repeat(5, 1fr) !important;
    background-color: #f2f2f5;
    padding: 16px 20px 16px 20px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 6px;
    gap: 0.4rem;
    width: 80% !important;

    .scan-response-key{
        color: rgba(39, 49, 66, 0.6);
        font-size: 0.8rem;
    }

    .scan-response-value{
        font-size: 0.8rem;
    }

    .highlighted-info{
        color: #1665d8;
    }
}