@import '../../../assets/styles/common.scss';

.common-card {
    width: 100%;
    border-radius: 6px !important;
    border: none !important;
}

.pod-drs-closure-home-initial-message-card {

    .pod-drs-closure-home-initial-message-text {
        text-align: center;
        font-size: 1.6rem;
    }
}

.validate-resync-btn {
    color: white !important;
    padding: 16px;
    border-radius: 8px;
    height: fit-content !important;
    letter-spacing: 0.05em;
    padding: 8px 16px 8px 16px !important;
    margin-left: 18px !important;
}

.validate-resync-btn:hover {
    color: white !important;
}

.validate-resync-card {
    @extend .common-card;
    .ant-card-body {
        width: 100%;
        @extend .flex-box, .align-items-center
    }
}

.fe-select-input {
    width: 290px;
}

.scan-card {
    @extend .common-card;
    >.ant-card-body {
        width: 100%;
        padding-top: 0px;
        padding-bottom: 0px;
        @extend .flex-column, .flex-gap-xl;
    }
}

.scan-info-card {
    @extend .common-card;
    .ant-card-body {
        width: 100%;
        background-color: #F6F6F6;
        border-radius: 4px;
        @extend .flex-box, .align-items-start;
    }
}

.scan-input-div-container {
    width: 100%;
    @extend .flex-box, .align-items-center;
}

.margin-left-16 {
    margin-left: 16px;
}

.count-card {
    width: 10em;
    border: 2px solid lightgrey;
    border-radius: 6px;
    .ant-card-body {
        padding: 4px;
        height: 100%;
        @extend .flex-column, .justify-content-center, .align-items-center;
    }
}

.display-text {
    font-size: 12px;
    font-weight: 500;
}

.sec-title {
    font-weight: 500;
    margin-bottom: 12px;
    font-size: 16px;
}

.info-card {
    @extend .common-card;
    >.ant-card-body {
        @extend .flex-column, .flex-gap-xl;
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

.drs-list-data {
    li {
        list-style-type: "-- " !important;
    }
}

.close-drs-modal {
    .ant-modal-body {
        @extend .flex-column, .flex-gap-xl;
    }
}

.count-text {
    font-size: 18px;
}

.div-info-container {
    column-gap: 5em;
    row-gap: 2em;
    @extend .flex-box, .flex-wrap, .flex-2;
}