@import "_variables";

#root
{
  & .lsn-btn-primary,
  & .lsn-btn-primary .ant-btn,
  & .ant-btn { 
    transition    : all 0.25s ease-in-out;
    border-radius : 6px;
    margin        : 0px;
    box-shadow    : none;
    text-shadow   : none;
    opacity       : 1;
  }

  & .ant-btn:disabled {
    color        : $light-grey-text;
    background   : $grey-bg;
    border-color : $grey-border;
    text-shadow  : none;
    box-shadow   : none;
    opacity      : 0.5;
  }

  & .ant-btn.input-suffix-button {
    right : -12px;
    border-radius : 0px 4px 4px 0px; 
    height: 32px
  }
  
}
.ant-btn.ant-btn-primary,
.ant-btn.ant-btn-primary:active,
.ant-btn.ant-btn-primary:focus,
.ant-btn.ant-btn-primary:hover,
.ant-btn.lsn-btn-primary,
.ant-btn.lsn-btn-primary:active,
.ant-btn.lsn-btn-primary:focus,
.ant-btn.lsn-btn-primary:hover,
.ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button {
  color        : $primary-text-color;
  background   : $primary-btn-bg;
  border-color : $primary-btn-border;
}

.ant-btn.lsn-btn-secondary,
.ant-btn.lsn-btn-secondary:active,
.ant-btn.lsn-btn-secondary:focus,
.ant-btn.lsn-btn-secondary:hover {
  color        : $secondary-text-color;
  background   : $secondary-btn-bg;
  border-color : $secondary-btn-border;
}

.ant-btn.lsn-btn-success,
.ant-btn.lsn-btn-success:active,
.ant-btn.lsn-btn-success:focus,
.ant-btn.lsn-btn-success:hover {
  color        : $success-text-color;
  background   : $success-btn-bg;
  border-color : $success-btn-border;
}

.ant-btn.lsn-btn-error,
.ant-btn.lsn-btn-error:active,
.ant-btn.lsn-btn-error:focus,
.ant-btn.lsn-btn-error:hover {
  color        : $error-text-color;
  background   : $error-btn-bg;
  border-color : $error-btn-border;
}

.ant-btn.lsn-btn-action,
.ant-btn.lsn-btn-action:active,
.ant-btn.lsn-btn-action:focus,
.ant-btn.lsn-btn-action:hover {
  color        : $action-button-text-color;
  background   : $action-button-btn-bg;
  border-color : $action-button-btn-border;
}

.remit-modal {
  .ant-select {
    width: 100%;
  }
}

.cursor-pointer:disabled {
  cursor: not-allowed !important;
}