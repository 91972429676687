.row-details:last-child {
    border-bottom: none !important;
}
.page-divider {
    margin-bottom: 30px;
}
@media print {
    .page-divider {
        page-break-after: always;
        margin-bottom: 100px;
    }
    table { page-break-after:auto }
    tr    { page-break-inside:avoid; page-break-after:auto }
    td    { page-break-inside:avoid; page-break-after:auto }
    thead { display:table-header-group }
    tfoot { display:table-footer-group }
    .ant-table-thead > tr > th {
        padding: 0px;
    }
    .ant-table-tbody > tr > td {
        padding: 0px;
    }
}
.ant-table-wrapper.black-bordered table {
    border-color: black !important;
    th, td {
        border-color: black !important;
    }
}
table.black-bordered, table.black-bordered th, table.black-bordered td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 2px 5px;
}
th {
    text-align: center;
}

td {
    text-align: left;
}