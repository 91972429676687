// Color
$labelColor: #9ea0a5;
$logoColor: #1B7777;
$primaryTextColor: #fff;
$secondartTextColor: #000;
$menu-bg : #EEF3FB;
$primaryColor : #1D1F2B;
$tabsColor : #1D1F2B;
$navBarColor : #1D1F2B;
$filterCheckboxColor : #1D1F2B;

// Sidebar colors
$sidebarBorderColor : #3b5a98;
$sidebarBgColor : #1D1F2B;
$sidebarTextColor : #ffffff;
$sidebarSelectedColor : #ffffff;
$sidebarSelectedTextColor : #1D1F2B;

// Size
$labelSize: 12px;
$primaryBtnSize: 14px;

// Button Settings
$primaryBtnBorder: 4px;
$emptyBorder: 1px solid transparent;

// Font Settings
$fontMedium: 500;
$fontSizeForMiniCard: 24px;

//default constants
$white-bg: #ffffff;
$grey-bg: #f9f9fa;
$dark-grey-bg: #ebebeb;
$font-text: #000;
$grey-text: #6f7073;
$light-grey-text: #a5a7af;

$grey-border: #dfe2e5;
$dark-grey-border: #b8bbbe;
$button-grey: #f2f2f5;
$dark-shadow: #dfe2e5;

//information colors
$warning-text: #DC791D;
$warning-border: #DC791D;
$warning-bg: #FEF3DC;
$warning-icon : #F28827;

$success-text: #50b154;
$success-border: #50b154;
$success-bg: #ebf5ea;

$error-text: #F22735;
$error-border: #F22735;
$error-bg: #FEEBED;

$info-text: #3370FF;
$info-border: #3370FF;
$info-bg: #EBEDF8;

$primary-info-bg: #EBEDF8;
$primary-info-text: #3370FF;
$primary-info-border: #3370FF;

$secondary-info-bg: #8080800f;
$secondary-info-text: #333;
$secondary-info-border: #333;

$tertiary-info-bg: #b33fb529;
$tertiary-info-text: #b33fb5;
$tertiary-info-border: #b33fb5;

$disabled-color : rgba(0, 0, 0, 0.25);

//button colors
$disabled-text-color   : #a5a7af;
$disabled-bg-color     : #f9f9fa;
$disabled-border-color : #dfe2e5;

$primary-btn-bg     : #1D1F2B;
$primary-btn-border : #1D1F2B;
$primary-text-color : #ffffff;
$primary-btn-shadow-color: #1D1F2B;


$secondary-btn-bg     : #ffffff;
$secondary-btn-border : #d9d9d9;
$secondary-text-color : #444444;

$error-btn-bg     : #FDD4D7;
$error-btn-border : #F22735;
$error-text-color : #F22735;

$success-btn-bg     : #c3f6ce;
$success-btn-border : #50b154;
$success-text-color : #50b154;

$action-button-btn-bg     : #F3F7FC;
$action-button-text-color : #1665D8;
$action-button-btn-border : #CBDFFC;