@import "../../../assets/styles/variables";

.assign-modal {
  .assign-summary {
    border-radius: 4px;
    border: solid 1px rgba(223, 226, 229, 0.3);
    background-color: #f2f2f5;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 12px 16px;
    margin-bottom: 16px;

    .assign-summary-count {
      display: flex;
      flex-direction: column;
      width: 50%;

      .label {
        color: rgba(39, 49, 66, 0.8);
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
      }

      .count {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 600;
        color: #273142;

      }
    }
  }
}

.load-btn {
  box-shadow: 0px 0px 6px 5px $primary-btn-shadow-color !important;
}