.select-root {
    width: 100%;
    .ant-select-selection {
        border-radius: 6px;
    }
    .ant-select-selection-selected-value {
        color: #E13024;
    }
    .ant-select-selection__placeholder {
        color: #E13024 !important;
    }
}

li.ant-select-dropdown-menu-item {
    white-space: break-spaces !important;
    text-overflow: unset !important;
}

.spin-root{
    margin-left: 2px !important;
    margin-top: 4px !important;
}