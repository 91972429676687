.closure-scan-card-container {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 500;
    flex-grow: 0;
    gap: 0.4rem;

    .manifest-scan-card-body {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        gap: 1rem;

        .manifest-scan-card-item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 0.6rem;
        }
    }

    .consignment-scan-card-body {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        .consignment-scan-card-body-row {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            gap: 1rem;

            .closure-scan-card-item {
                display: flex;
                flex-direction: column;
                // justify-content: space-between;
                gap: 0.6rem;
            }
        }
    }
}

.consignment-scan-card-body-container {
    background-color: #f2f2f5;
}

.closure-scan-card-key{
    color: rgba(39, 49, 66, 0.6);
    font-size: 0.8rem;
}

.closure-scan-card-value {
    font-size: 0.8rem;
    .closure-scan-card-buket {
        display: flex;
        justify-content: flex-start;
        gap: 1rem;
    }
}

.highlighted-info{
    font-size: 18px;
    color: #1665d8;
}

.highlighted-info-red{
    color: #1665d8;
}

.span-2{
    grid-column: span 2;
}
.span-3{
    grid-column: span 3;
}

.span-4{
    grid-column: span 4;
}

.dropdown-section {
    display: flex;
    justify-content: center;
}

.overage-bold {
    font-weight: bold;
    color: #FF0000;
    font-size: medium;
}