@import "./variables";

.lsn-filter-options-checkbox .input-wrapper.ant-checkbox-group, .lsn-filter-options-checkbox.ant-checkbox-group {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row !important;
    .ant-checkbox-wrapper {
        margin: 0px;
        display: flex;
        justify-content: center;
        flex: auto;
    }
    .ant-checkbox {
        display: none;
    }
    .ant-checkbox-wrapper-checked .ant-checkbox-checked + span {
        background: #eff6fd;
        border: 1px solid #0075f5;
        color: #0075f5;
        &:hover {
            border: 1px solid #0075f5;
            color: #0075f5;
        }
    }
    
    .ant-checkbox + span {
        border: 1px solid lightgrey;
        padding: 4px 24px;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        justify-content: center;
        width: 100%;
        &:hover {
            background: #eff6fd;
            border: 1px solid #599fe9;
            color: #0663c7;;
        }
    }   
}

.report-preview-radio.ant-radio-wrapper {
    margin: 0px;
    min-width: 80px;
    .ant-radio {
        display: none;
    }
    .ant-radio + span {
        padding: 4px 10px;
        font-weight: 100;
        font-size: 13px;
        background-color: #f8fafc;
        border: 1px solid #ced0da;
        color: #354052;
        width: 100%;
        display: inline-block;
        text-align: center;
    }
    .ant-radio.ant-radio-checked + span {
        background-color: $filterCheckboxColor;
        border: 1px solid $filterCheckboxColor;
        color: #fff;
    }
}

.select-filter {
    .ant-select-selection.ant-select-selection--multiple {
        padding: 4px 0px;
        .ant-select-arrow {
            top: 20px;
        }
    }
    .ant-select-selection__choice.ant-select-selection__choice__disabled {
        margin: 0;
        height: 30px;
        border: 0;
        background: none;
        padding: 4px 4px 6px;
        color: #B7B7B7;
        position: absolute;
    }
}

.ant-input-affix-wrapper.scan-awb-box { 
    height: 35px !important;
    input {
        padding-left: 12px;
    }
}

.pickup-date-filter.ant-calendar-picker .ant-calendar-picker-input.ant-input {
    height: auto;
    padding: 7px 0px
}

.remove-padding {
    .ant-modal-body {
        padding: 0px;
    }
}
.remove-card-body-padding .ant-card-body {
    padding: 0;
}
.remove-card-bg {
    background: transparent;
}

.ant-select.ant-select-disabled .ant-select-arrow {
    display: none;
}

.log10-tabs .ant-tabs-tab-active.ant-tabs-tab, .log10-tabs .ant-tabs-nav .ant-tabs-tab:hover {
    color: $tabsColor;
}

.log10-tabs .ant-tabs-tab {
    font-size: 15px;
}

.log10-tabs .ant-tabs-nav-container {
    padding: 0px 20px;
}

.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background-color: $tabsColor;
    height: 4px;
}

.radio-button-spacer {
    label.ant-radio-button-wrapper {
        border: 1px solid lightgrey;
        border-radius: 4px;
        margin: 4px 8px;
        &.ant-radio-button-wrapper-checked {
            box-shadow: none;
            border-color: #1890ff;
        }
        &::before {
            display: none;
        }
    }
}

.table-cell-bg-white tbody.ant-table-tbody>tr:nth-child(odd)>td {
    background-color: #FFFFFF;
}

.table-cell-bg-white tbody.ant-table-tbody>tr:nth-child(even)>td {
    background-color: #F9F9F9;
}

.table-cell-bg-white  .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
    background: #FFFFFF;
}

.ant-alert-warning.cod-banner {
    background-color: #f6c983;
    border-color: #d03920;
}

#root {
    .ant-table-small > .ant-table-content > .ant-table-body {
        margin: 0;
    }
}

.ant-table-thead > tr > th .anticon-filter.icon-position-overwrite {
    position: static;
    & svg {
        position: static;
        margin: 0;
    }
}

fieldset.default-fieldset {
    padding: 8px 8px;
    border: 1px solid $grey-border;
    legend {
        font-size: 16px;
        width: auto;
    }
}