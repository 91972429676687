@import "../../../../../assets/styles/_variables.scss";

.remove-btn {
    font-size: 24px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50% !important;
    width: 30px !important;
    color: $warning-text;
    border: 1px solid $warning-border;
    margin-left: 30px !important;
    height: 28px;
}

.remove-btn:hover {
    color: $warning-text;
    border: 1px solid $warning-border;
}

.list-group {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.list-group-item {
    list-style-type: none;
    display: flex;

    >.form-group:first-child {
        flex: 1 1;
    }
}

.add-item-btn::after {
    content: "+";
}

.add-item-btn {
    background-color: #1D1F2B;
    color: #ffffff;
    padding: 4px 16px 4px 16px;
    border-radius: 6px;
    border: none;
    height: 32px;
}

.add-item-btn:hover {
    cursor: pointer;
}

.field-array {
    width: 100%;
}