.scan-box {
  padding: 24px;
  background-color: #f6f6f6;
  border-top: 2.5px dotted #eaeaea;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(115, 115, 115, 0.2);
  box-shadow: 0 2px 4px 0 rgba(115, 115, 115, 0.2);
  margin-bottom: 30px;
}

.audit-scan-box {
  padding: 24px;
  background-color: #f6f6f6;
  border-top: 2.5px dotted #eaeaea;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(115, 115, 115, 0.2);
  box-shadow: 0 2px 4px 0 rgba(115, 115, 115, 0.2);
  margin-bottom: 30px;
  
  .key {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    color: #9ea0a5;
  }

  .value {
    font-size: 16px;
  }
}

.scan-box {
  padding: 24px;
  background-color: #f6f6f6;
  border-top: 2.5px dotted #eaeaea;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(115, 115, 115, 0.2);

  ul {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
  }

  .key {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    color: #9ea0a5;
    padding-bottom: 10px;
  }

  .value {
    font-size: 16px;
  }

  .value.pincode {
    font-size: 16px;
    font-weight: bold;
    color: #405dfe;
    text-transform: uppercase;
  }

  .value.waybill {
    text-transform: uppercase;
  }

  .value.address {}

  .address-float-right {
    float: right
  }
}

.scan-box ul.second-row-scan li:first-child {
  width: 220px;
  margin-right: 214px;
}

.scan-box ul.third-row-scan li:first-child {
  margin-bottom: 0;
}

.scan-box ul.second-row-scan li:last-child {
  width: 47%;
  margin-left: 0;
}

.scan-box ul.third-row-scan li {
  margin: 0 10px 16px 00;
}

.scan-bucket {
  background-color: #a4a4a4;
  border-radius: 4px;
  padding: 1px 8px;
  color: #ffffff;
}

.scan-bucket {
  &.background-warning {
    background-color: #d32f2f !important
  }

  &.background-medium {
    background-color: #f6ab2f !important
  }

  &.background-normal {
    background-color: #6758f3 !important;
  }
}

.scan-box ul li {
  display: inline-block;
  vertical-align: top;
  margin: 0 80px 16px 00;
  min-width: 46px;
}
