@import "../../assets/styles/utilities";

.TrackingPopup {
    background-color: #fff;
    .header {
        width: 100%;
        height: 65px;
        border-bottom: 1px solid lightgray;
        padding: 15px;
        font-family: Lato;
        font-size: 24px;
        font-weight: bold;

        .copy-icon {
            color: lightgray;
            margin: 0 10px;
            cursor: pointer;
        }
    }

    .sidebar {
        border-right: 1px solid lightgray;
        .ant-collapse-header {
            font-weight: 500 !important;
            font-style: normal !important;
            font-stretch: normal !important;
            line-height: 1.5 !important;
            letter-spacing: normal !important;
            color: #3b5a98 !important;
        }

        .pod-image {
            width: 50px;
            height: 50px;
            margin: 2px;
            border: 1px solid;
            border-radius: 5px;
            color: #007bff;
        }

        .row {
            display: flex;
            justify-content: space-between;
            margin: 8px 0px;
        }

        .bold {
            color: #9ea0a5;
            width: 45%;
        }

        .data {
            width: 55%;
            color: #000000;
        }
    }

    .main-content {
        max-height: 75vh;
        overflow-y: auto;
        .expanded-row {
            .left-col {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                max-height: 150px;
            }

            .expanded-data-row {
                margin-bottom: 10px;

                p {
                    margin: 0
                }

                .heading {
                    font-size: 12px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #9ea0a5;
                }

                .data {
                    font-size: 14px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.57;
                    letter-spacing: normal;
                    color: #3e3f42;
                }

                .init-caps {
                    text-transform: capitalize;
                }

                .flex {
                    display: flex;
                    width: 230px;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    .inline {
                        display: inline;
                        margin-right: 5px;
                    }
                }
            }
        }
        .icon-warning {
            font-size: 19px;
            color: #ff6d00;
        }
    }
}

.expand-link {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: normal;
    @extend .primary-text;
}

.expand-link:hover {
    @extend .primary-text;
}

.greyed-out {
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #9ea0a5;
}

.clickable-link{
    color: #3b5a98;
    cursor: pointer;
}