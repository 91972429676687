@import "../../../assets/styles/common.scss";

// Common css

.mt-20 {
    margin-top: 20px;
}

// Common css end

.form-container #root>.form-group {
    border-top: 1px solid lightgray;
    padding-top: 20px;
}

.form-container #root .single-field-multi-row-form {
    border-top: 0px;
}

.vendor-container .form-group .field .add-item-btn:after {
    content: "Click here to enter new vendor details";
}

.vendor-container .form-group .hidden {
    display: none;
}

.location-client,
.rvp-location-client {
    padding-top: 20px;
    border-top: 1px solid lightgray !important;
    width: 100%;
}

.location-client .row .array-item-add .btn-add,
.rvp-location-client .row .array-item-add .btn-add {
    border-radius: 4px !important;
    width: fit-content !important;
    padding: 14px !important;
}

.location-client .form-group .field .add-item-btn:after {
    content: "Click here to enter new client details" !important;
}

.rvp-location-client .form-group .field .add-item-btn:after {
    content: "Click here to enter new RVP client details" !important;
}

.location-client .form-group,
.rvp-location-client .form-group {
    border-top: none !important;
}

.location-client .field-array,
.rvp-location-client .field-array {
    width: 100%;
    padding-top: 0px !important;
}

.location-client .list-group .array-item,
.rvp-location-client .list-group .array-item {
    padding-top: 30px;
}

.location-client .list-group .array-item:not(:first-child),
.rvp-location-client .list-group .array-item:not(:first-child) {
    border-top: 1px solid lightgray !important;
}

.lsn-form.column-fields fieldset {
    flex: 1;
}

.CustomerDashBoard1 {

    .ant-pagination,
    .ant-table-pagination {
        float: right !important;
    }

    .ant-card-head-title {
        padding: 8px 0px 0px;
    }

    .ant-card-body label.control-label {
        flex: none;
        font-size: 16px;
        padding: 0px !important;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.existing-partner-radio {
    padding-top: 0px !important;
}

.location-group .array-item-list {
    margin-left: 1em;

    .array-item {
        border-bottom: 1px solid lightgray;
        padding-bottom: 20px;
        padding-top: 20px;
    }
}

.location-group .row .array-item-add .btn-add {
    border-radius: 4px !important;
    width: fit-content !important;
    padding: 14px !important;
}

.location-group .row .array-item-add .btn-add:after {
    content: "Click here to enter new location details";
}

// .location-group .array-item .array-item-remove {
//     padding: 14px;
//     margin-left: 10px;
// }

.partner-container #root_partner .form-group {
    margin-left: 1em
}

.user-container .form-group {
    margin-left: 1em;
}

.client-name select {
    width: 14em;
}

.existing-partner-select-input {
    border-top: none !important;
    padding-top: 0px !important;
}

.connection-container .ant-select-disabled .ant-select-selection,
.loc-onboarding-container .ant-select-disabled .ant-select-selection {
    background: #f5f5f5 !important;
}

.loc-onboarding-container input:read-only {
    background: #F0F0F0 !important;
}

.connection-container .form-group .field-array .list-group .list-group-item {
    width: 100%;
    align-items: center;
}

.connection-container .form-group .field-array .list-group .list-group-item .form-group {
    width: 100%;
    flex: auto;
}

.connection-container .form-group .field-array .list-group .list-group-item .form-group fieldset {
    flex: none;
    flex-direction: row;
    width: 100%;
}

.connection-container .form-group .field-array .list-group .list-group-item .form-group fieldset .field {
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

.connection-container .form-group .field .add-item-btn:after {
    content: "Click here to enter new connection details";
}

.connection-form .flex-1 {
    flex: none;
    width: 100%;
}

.connection-form .lsn-form {
    width: 100%;
}

.layout-card {
    margin: 20px 0px 20px 0px;
}

.vendor-info-text,
.connection-info-text {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.connection-container input:read-only {
    background: #F0F0F0 !important;
}

.location-client .form-group .field-array .list-group .array-item:not(:first-child) {
    border-top: 1px solid lightgray !important;
    padding-top: 20px;
}