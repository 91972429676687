.manifest-unload-card-body-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .mainfest-unload-card-info-container{
        display: flex;
        justify-content: space-between;
        align-items: center;

        .manifest-unload-card-flag-span {
            // padding-left: 3%;
            color: #1665d8;
        }

        .manifest-unload-card-info-buttons {
            display: flex;
            justify-content: space-between;
        }
    }

    .manifest-unload-card-sparkline-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 20px;
        border: none;
    }

    .manifest-unload-card-scan-input {
        width: 60%;

        .ant-input-search-icon {
            display: none;
        }
    }

    .manifest-unload-validated{
        display: flex;
        justify-content: flex-end;

        .manifest-unload-validated-info {
            width: 30%;
        }
    }

    .manifest-unload-card-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .manifest-unload-card-list-container {
            display: flex;
            flex-direction: column;
            background-color: #f2f2f5;
            padding: 16px 20px 16px 20px;
            font-size: 12px;
            font-weight: 500;
            border-radius: 6px;
            flex-grow: 0;
            gap: 0.4rem;

            .manifest-unload-card-list-grid {
                display: grid;
                grid-template-columns: repeat(7, 1fr);
                gap: 1rem;

                .header-text{
                    color: rgba(39, 49, 66, 0.6);
                }
            }

            .item-body{
                font-size: 13px;
            }

            .manifest-unload-card-list-grid-address {
                display: flex;
                
                .address {
                    flex: 80%;
                }
                .option{
                    flex: 20%;

                    .reason-more-button{
                        // background-color: #f2f2f5;
                        width: 20px;
                        height: 20px;
                        border-radius: 3px;
                        // padding: 2px;
                    }
                }
            }
        }

    }
}

.span-2{
    grid-column: span 2;
}
.span-3{
    grid-column: span 3;
}


.error-dialog-container{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .error-dialog-title{
        text-align: center;
        font-size: medium;
    }

    .error-dialog-footer{
        display: flex;
        justify-content: center;

        .error-dialog-button {
            width: 80%;
        }

    }
}


// for mweb
@media only screen and (max-width: 600px) {
    .manifest-unload-card-body-container.mweb-wrapper {
        gap: 10px;
        .mainfest-unload-card-info-container{
            display: flex;
            padding: 12px;
            justify-content: space-between;
            background-color: #dfe3f8;
            .manifest-unload-card-header  {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 4px;
                & > span {
                    font-weight: bold;
                }
            }
            .manifest-unload-card-info-buttons {
                display: none;
            }
        }
        .manifest-unload-card-sparkline-container {
            gap: 10px;
            padding: 0 10px;
            box-sizing: border-box;
            .unload-sprakiline-button {
                .ant-btn {
                    height: auto;
                    text-align: left;
                    border: none;
                    padding: 8px;
                    margin: 0;
                    div {
                        line-height: 1;
                        align-items: start;
                        flex-direction: column;
                        i {
                            display: none;
                        }
                    }
                }
            }
        }
        .manifest-unload-validated{
            display: flex;
            padding: 0px 10px;
            .manifest-unload-validated-info {
                width : auto;
                flex  : 1 1;
            }
        }
        .manifest-unload-card-scan-input {
            padding: 0 10px;
            width: auto;
        }
    }
}