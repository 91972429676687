.App > section.ant-layout {
    width: 100vw;
    min-height: 100vh;
    .app-wrapper {
      width: 100%;
      min-height: 100vh;
      .body-container {
        height: 100%;
      }
    }
}

#header-logo {
  path[fill='#0B5559'] {
    fill: #fff;
  }
}
  