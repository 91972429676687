.drs-detailed {
    &-title {
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        &_blue {
            color: #1665d8;
        }
    }
}

.drs-summary {
    &-title {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: left;
        color: #424242;
    }
    &-label {
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: rgba(39, 49, 66, 0.8);
    }
    &-value {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: #273142;
    }
    .flex-grid {
        display: flex;
        flex-wrap: wrap;
    }
    .grid-item {
        flex: 5 0 calc(100% - 60%) !important;
    }
    .grid-item:nth-child(odd) {
        flex: 5 0 50%;
    }
}

.drs-table {
    &-del {
        color: #4caf50;
    }
    &-undel {
        color: #ff9800;
    }
    .bullet {
        width: 4px;
        height: 4px;
        background-color: #000;
        border-radius: 4px;
    }
    .date-from {
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: rgba(0, 0, 0, 0.6);
    }
    .total-pagination {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // padding: 10px 8px;

        // position: absolute;
        width: 80px;

        background: #ffffff;
        border: 1px solid #dfe2e5;
        box-sizing: border-box;
        border-radius: 4px;
    }
}

.drs-basic {
    &-btn,
    &-btn:hover,
    &-btn:focus {
        background-image: linear-gradient(to top, #1665d8 98%, #1f6fe5 2%);
        border-radius: 4px;
        box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1);
        border: solid 0 #1665d8;
        color: #fff;
    }
    &-scanned {
        border: 1px dashed #dfe2e5;
    }
}
.drs-close {
    &-heading {
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #424242;
    }
    &-user {
        &_label {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            text-align: left;
            color: rgba(39, 49, 66, 0.72);
        }
        &_value {
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            text-align: left;
            color: #273142;
        }
    }
}
